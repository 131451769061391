<template>
  <NuxtLoadingIndicator style="position: sticky;" />
  <div class="container-fluid">
    <HFunnelTopBar />
    <div class="container">
      <div class="row">
        <div class="col-12 py-5">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const topBarHeight = ref('')

useHead({
  bodyAttrs: {
    class: 'funnel'
  }
})

onMounted(() => {
  const $funnelNavBar = document.querySelector('.funnel-navbar')
  topBarHeight.value = $funnelNavBar?.scrollHeight + 'px'
})
</script>

<style lang="scss" scoped>
.left-column {
  position: sticky;
  top: v-bind(topBarHeight);
  height: calc(100vh - v-bind(topBarHeight));

  .map {
    height: 100%;
    width: 100%;
  }
}

:deep(.v-label) {
  &:not(.v-label--clickable, .v-field-label) {
    font-weight: bold;
  }
}

:deep(.v-radio-group > .v-input__control > .v-label) {
  margin-inline-start: unset !important;
}

</style>
